import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { SEO, Layout } from '../components'
import styles from './teklif-al.module.css'
import Img from 'gatsby-image'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
export default function TeklifAl({ data }) {
  const [formState, setFormState] = useState({
    firstName: '',
    phone: '',
    ulasim: '',
    email: '',
  })
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formState })
    })
      .then(() => alert('Teklif Kaydınız Başarıyla Kaydedildi!'))
      .catch((error) => alert(error))

    e.preventDefault()
  }

  return (
    <Layout>
      <SEO title="Teklif Al" description="Timurlar Çatalca Sigortadan hemen Sigorta Teklifini alabilir karşılaştırmalı uygun sigorta fiyatlarını görebilirsiniz." />
      <div className={['page-content', styles.teklif].join(' ')}>
        <div className="overlaybg">
          <Img fluid={data.homeImage.childImageSharp.fluid} />
        </div>
        <div className="container">
          <form name="contact" method="POST" data-netlify="true">
            <div className="row">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <form
                  onSubmit={handleSubmit}
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  aria-label="teklif formu"
                  className={styles.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="Ad Soyad"
                        name="firstName"
                        autoComplete="fname"
                        value={formState.firstName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="phone"
                        name="phone"
                        variant="outlined"
                        required
                        fullWidth
                        id="phone"
                        label="Telefon"
                        value={formState.phone}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        value={formState.email}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <label htmlFor="kvkk">
                        <Checkbox
                          id="kvkk"
                          name="kvkk"
                          value="allowExtraEmails"
                          color="primary"
                          required
                        />
                        <Link to="#">Kvkk</Link> Metnini okudum onaylıyorum.
                      </label>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className="mt-4">
                    Teklif Gönder
                  </Button>
                </form>
              </div>
              <div className="col-lg-6 pl-lg-5 d-flex justify-content-center flex-column">
                <h1>
                  35 Şirketten Karşılaştırmalı <strong>Trafik Sigortası</strong>{' '}
                  Teklifi
                </h1>
                <p>
                  Hasar durumlarında karşı tarafta oluşan maddi zararları ve
                  bedeni zararları güvence altına alan ve karşı taraftaki
                  hasarları ödeyen bir sigorta ürünüdür.
                </p>
                <ul className="icon-list">
                  <li data-icon="🏎"> 35 sigorta şirketinden hızlı teklif</li>
                  <li data-icon="🚧"> Hasar sonrası destek</li>
                  <li data-icon="🚚"> Ücretsiz Kargo</li>
                  <li data-icon="☂️">Kasko poliçesinde %5 indirim kazan</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    homeImage: file(name: { eq: "banner" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
